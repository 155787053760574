export default {

    dataMocks: {

        energyResultMock: {
            label: 'output_energy_testing',
            description: 'mock energy results',
            energy: -14.572583485674794,
            log: '  ******** <class pyscf.df.df_jk.density_fit.< locals >.DFHF> ******** method = DFHF-D...',
            hqschema: { label: 'quant_chem_single_point_output', version: '0.1' },
            _user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec",
            _uuid: "95f72b24-38df-4779-9af3-f7dd984fee01",
            _cdate: "2023-02-24 12:41:50.050684",
            _source: { user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec" },
        },
        moleculeMock: {
            label: "H2",
            description: "test",
            atoms: [
                {
                    symbol: "H",
                    position: [
                        0,
                        0,
                        0
                    ]
                },
                {
                    symbol: "H",
                    position: [
                        0,
                        0,
                        1
                    ]
                }
            ],
            hqschema: {
                label: "molecule",
                version: "0.1"
            },
            _user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec",
            _uuid: "a016e4a4-70af-4672-a68e-12099fd7acf3",
            _cdate: "2023-03-08 16:09:49.218640",
            _source: {
                user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec"
            }
        },
        deviceMock: {
            label: "device 1",
            description: "test",
            single_qubit_gates: "RotateX, RotateY, RotateZ",
            single_qubit_gate_times: [
                {
                    single_qubit_gate: "RotateX",
                    gate_time: 1
                }
            ],
            two_qubit_gate: "CNOT",
            two_qubit_gate_times: [],
            decoherence_rates: [
                {
                    qubit: "0",
                    damping: 0,
                    dephasing: 0,
                    depolarising: 0
                }
            ],
            hqschema: {
                label: "device",
                version: "0.1"
            },
            _user: "286bec7e-0c32-4b5c-881c-f3b24393d7f6",
            _uuid: "27576aea-d8e2-47a5-b092-06783b5dafdf",
            _cdate: "2023-03-22 10:24:53.645071",
            _source: {
                user: "286bec7e-0c32-4b5c-881c-f3b24393d7f6"
            }
        },
        hamiltonianMock: {
            label: "hamil 1",
            description: "test",
            number_spins: "1",
            single_spin_terms: [
                {
                    single_spin_operator: "0Z",
                    value: 1
                }
            ],
            two_spin_terms: [],
            hqschema: {
                label: "hamiltonian",
                version: "0.1"
            },
            _user: "286bec7e-0c32-4b5c-881c-f3b24393d7f6",
            _uuid: "6687b79a-603b-48ad-8502-4818affa120d",
            _cdate: "2023-03-22 10:25:37.211049",
            _source: {
                user: "286bec7e-0c32-4b5c-881c-f3b24393d7f6"
            }
        },
        pyscfParamsMock: {
            label: "calculator",
            description: "test",
            convergence_preset: "normal",
            spin: 0,
            charge: 0,
            spin_formalism: "restricted",
            method: "HF",
            basis: "def2tzvp",
            dispersion: "D3BJ",
            SCF_convergence: "normal",
            hqschema: {
                label: "pyscf_calculator",
                version: "0.1"
            },
            _user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec",
            _uuid: "e97984dd-95e6-4656-adc7-a98994433a95",
            _cdate: "2023-04-03 12:22:25.619535",
            _source: {
                user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec"
            }
        },
    }
}