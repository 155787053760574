<template>
  <div v-if="isTestUser">
    <v-btn
      data-cy="create_structure"
      depressed
      outlined
      x-small
      color="primary"
      class="ml-5"
      @click="createStructure"
    >
      create structure
    </v-btn>
    <v-btn
      data-cy="create_job"
      depressed
      outlined
      x-small
      color="primary"
      class="ml-5"
      @click="createJob"
    >
      create job
    </v-btn>
    <v-btn
      data-cy="create_result"
      depressed
      outlined
      x-small
      color="primary"
      class="ml-5"
      @click="createResult"
    >
      create result
    </v-btn>
    <v-btn
      data-cy="create_params"
      depressed
      outlined
      x-small
      color="primary"
      class="ml-5"
      @click="createParams"
    >
      create params
    </v-btn>
    <v-btn
      data-cy="clear_testing_env"
      depressed
      outlined
      x-small
      color="primary"
      class="ml-5"
      @click="clearTestingEnvironment"
    >
      clear all
    </v-btn>
  </div>
</template>

<script>
import auth from '../../views/lib/auth'
import jobMocks from '../../../tests/e2e/mocks/jobMocks'
import dataMocks from '../../../tests/e2e/mocks/dataMocks.js'
import { mapActions, mapState } from 'vuex'
import { cloneDeep } from 'lodash-es'

export default {
  data() {
    return {
      userID: '',
      testUserJobs: '3cae6a66-5b57-4884-b6e1-01fc9445d5ec',
      testUserStructures: 'c59e3740-4f39-4920-89f4-b3c0062acf6d',
      isTestUser: false,
    }
  },

  methods: {
    ...mapActions('backend', ['create', 'createPredefined', 'delete', 'load']),

    async createStructure() {
      try {
        await this.createPredefined(['data', dataMocks.dataMocks.moleculeMock])
      } catch (error) {
        console.log(error)
      }
    },

    async createJob() {
      try {
        await this.create(['process', jobMocks.jobMocks.pineapple])
      } catch (error) {
        console.log(error)
      }
    },

    async createResult() {
      try {
        await this.createPredefined(['data', dataMocks.dataMocks.energyResultMock])
      } catch (error) {
        console.log(error)
      }
    },

    async createParams() {
      try {
        await this.createPredefined(['data', dataMocks.dataMocks.pyscfParamsMock])
      } catch (error) {
        console.log(error)
      }
    },

    async clearTestingEnvironment() {
      await this.clearJobs()
      await this.clearDatas()
      window.location.reload()
    },

    async clearDatas() {
      try {
        await this.load(['data'])
        for (let data of cloneDeep(this.datas)) {
          await this.delete(['data', data._uuid])
        }
      } catch (error) {
        console.log(error)
      }
    },

    async clearJobs() {
      await this.load(['process'])
      try {
        for (let process of cloneDeep(this.processes)) {
          await this.delete(['process', process._uuid])
          await this.load(['process'])
        }
      } catch (error) {
        console.log(error)
      }
    },
  },

  computed: {
    ...mapState('backend', {
      datas: (state) => state.datas,
      processes: (state) => state.processes,
    }),
  },

  async created() {
    await auth.getUser().then((user) => {
      if (
        user.id === this.testUserJobs ||
        user.id === this.testUserStructures
      ) {
        this.userID = user.id
        this.isTestUser = true
      }
    })
  },
}
</script>

<style lang="css" scoped></style>
