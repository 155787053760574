export default {

    jobMocks: {
        pineapple: {
            hqschema: {
                label: "process_schema",
                version: "1.0"
            },
            label: "pineapple",
            description: "test",
            inputs: {
                loc_mol_data: "a016e4a4-70af-4672-a68e-12099fd7acf3",
                loc_calc_data: "e97984dd-95e6-4656-adc7-a98994433a95"
            },
            workflow: {
                label: "abinitio_pyscf_energy",
                version: "0.1"
            },
            _uuid: "a0a01e23-7f0d-432a-9c19-9aaf9aa10bdf",
            _user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec",
            _cdate: "2023-02-23 15:20:20.430644",
            _source: {
                user: "3cae6a66-5b57-4884-b6e1-01fc9445d5ec"
            },
            state: "COMPLETED",
            outputs: { output_energy_testing: '95f72b24-38df-4779-9af3-f7dd984fee01' }
        },
        openSystemsMock: {
            hqschema: {
                label: "process_schema",
                version: "1.0"
            },
            label: "noise",
            description: "test",
            inputs: {
                job_parameters_input: "ad9a0c7b-cea1-43fe-8666-a4b927789d15",
                device_input: "27576aea-d8e2-47a5-b092-06783b5dafdf",
                hamiltonian_input: "6687b79a-603b-48ad-8502-4818affa120d"
            },
            workflow: {
                label: "noise_app",
                version: "0.1"
            },
            _uuid: "58fad08a-5fe2-489c-9abc-dbf60bbcd167",
            _user: "286bec7e-0c32-4b5c-881c-f3b24393d7f6",
            _cdate: "2023-03-23 07:23:14.139406",
            _source: {
                user: "286bec7e-0c32-4b5c-881c-f3b24393d7f6"
            },
            state: "COMPLETED",
            outputs: {}
        }
    }
}